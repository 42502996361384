import React from 'react';
import Head from 'next/head';
import config from 'src/config';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

export default function Home() {
  return (
    <Flex align="center" justify="center" h="100vh">
      <Head>
        <title>moEV Auto</title>
        <meta name="description" content="moEV Auto" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Box bg="white" p={6} rounded="md">
        <Flex align="center" justify="center">
          <Image
            boxSize="150px"
            src="/moev-logo-white-bg-square.png"
            alt="Moev Auto"
            justifySelf={'center'}
          />
        </Flex>
        <Text textAlign={'center'} color={'#efefef'}>
          {config.env}
        </Text>
      </Box>
    </Flex>
  );
}
